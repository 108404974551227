import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Top Crypto Bounty" />
    <div className="logo">
      <img src="/static/images/tcp_logo.png" alt="TopCryptoBounty"/>
    </div>
  </Layout>
)

export default IndexPage
